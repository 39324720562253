<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <app-view-toolbar
        title="Events"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="py-2 px-6">
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.items"
        :items-per-page="itemsPerPage"
        no-data-text="Inga event finns upplagda"
        no-results-text="Inga event hittades"
        :page.sync="page"
        @click:row="onOpenUpdate"
        @page-count="pageCount = $event"
        hide-default-footer
        class="click-table"
        locale="sv-SE">
      </v-data-table>
    </v-container>
  </div>
</template>
  
<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import useCore from '../../helpers/core';
import ViewToolbar from '../../components/ViewToolbar.vue'

export default {
  name: 'eventList',
  components: {
    'app-view-toolbar': ViewToolbar,
  },
  setup() {
    const { callApi } = useCore();
    const router = require('@/router/router').default;

    const dataLoaded = ref(false);
    const tableData = reactive({
      headers: [
        { text: "Namn", value: "evn_str_name" },
        { text: "Startdatum", value: "evn_dat_start" },
        { text: "Slutdatum", value: "evn_dat_end" },
        { text: "Publicerat", value: "evn_bit_public_schedule" },
        { text: "Plats", value: "evn_str_location" },
      ],
      items: [],
    });

    // Actions
    const onOpenCreate = () => {
      router.push({ name: 'eventPut', params: { id: 0 }});
    };
    const onOpenUpdate = (event) => {
      router.push({ name: 'eventPut', params: { id: event?.evn_id } });
    };
    
    // Toolbar actions
    const toolbarButtons = [{ icon: 'plus', name: 'Nytt event', fn: onOpenCreate }];
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      callApi({ method: 'get', path: '/cms/events' })
        .then((res) => {
          dataLoaded.value = true;
          tableData.items = res;
        })
        .catch((err) => {
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      dataLoaded,
      tableData,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      toolbarButtons,
      onToolbarButton,
      onOpenUpdate,
    };
  },
};
</script>

<style>
</style>